<template>
  <div class="py-7 px-8">
    <v-alert type="info" class="mb-6" outlined color="primary">
      <span class="dark-grey--text"
        ><b class="black--text">Important</b><br />
        Block Outs are used to prevent bookings from being made on specific
        dates. You can create a Block Out for a specific date or a range of
        dates. Block Outs can be created for the entire venue or for specific
        areas/table within the venue.
      </span>
    </v-alert>
    <p>Create new Block Out</p>
    <v-form v-model="is_valid" class="form" ref="block_out_form">
      <div class="--row">
        <div>
          <h1 class="text--secondary">Type <span class="red--text">*</span></h1>
          <p>
            Select the type of block out you want to create. You can create a
            block out for a single date, a date range or a specific day in a
            range.
          </p>
        </div>
        <v-radio-group v-model="block_type" row>
          <v-radio label="Single Date" value="date"></v-radio>
          <v-radio label="Date Range" value="date-range"></v-radio>
          <v-radio label="Specific day in range" value="day-in-range"></v-radio>
        </v-radio-group>
      </div>
      <div class="--row">
        <div>
          <h1 class="text--secondary">
            Description <span class="red--text">*</span>
          </h1>
          <p>
            Block out description is used to identify the block out in the
            system.
          </p>
        </div>
        <v-text-field
          v-model="block_out_name"
          label="Description"
          outlined
          dense
          clearable
          :rules="[field_req]"
          class="mb-6"
        />
      </div>

      <div class="--row" v-if="block_type === 'day-in-range'">
        <div>
          <h1 class="text--secondary">Days <span class="red--text">*</span></h1>
          <p>
            Select the type of block out you want to create. You can create a
            block out for a single date, a date range or a specific day in a
            range.
          </p>
        </div>
        <div style="grid-area: right">
          <v-checkbox
            v-for="{ label, value } in week_days_list"
            :key="value"
            :label="label"
            dense
            hide-details
            :input-value="selected_week_days.includes(value)"
            @change="on_week_day_click(value)"
          />
        </div>
      </div>

      <div class="--row">
        <div>
          <h1 class="text--secondary">
            Date(s) <span class="red--text">*</span>
          </h1>
          <p>
            Select the date(s) for which you want to create a block out. If you
            select a date range, you will need to select a start and end date.
          </p>
        </div>
        <div style="grid-area: right">
          <v-radio-group v-model="date_type" row>
            <v-radio label="Whole Day" value="date"></v-radio>
            <v-radio label="Specific Hours" value="datetime"></v-radio>
          </v-radio-group>
          <div class="d-flex" style="gap: 0.5rem">
            <v-text-field
              v-model="start_date"
              type="date"
              :label="block_type === 'date' ? 'Date' : 'Start Date'"
              outlined
              dense
              clearable
              :rules="[field_req]"
              class="mb-6"
            />
            <v-text-field
              v-if="block_type !== 'date'"
              v-model="end_date"
              type="date"
              label="End Date"
              outlined
              dense
              clearable
              :rules="[field_req]"
              class="mb-6"
            />
          </div>
          <div
            v-if="date_type === 'datetime'"
            class="d-flex"
            style="gap: 0.5rem"
          >
            <v-text-field
              v-model="start_at"
              type="time"
              label="Start Time"
              outlined
              dense
              clearable
              :rules="[field_req]"
              class="mb-6"
            />
            <v-text-field
              v-model="end_at"
              type="time"
              label="End Time"
              outlined
              dense
              clearable
              :rules="[field_req]"
              class="mb-6"
            />
          </div>
        </div>
      </div>

      <div class="--row mb-4">
        <div>
          <h1 class="text--secondary">
            Tables <span class="red--text">*</span>
          </h1>
          <p>Select the tables for which you want to create a block out.</p>
        </div>
        <div style="grid-area: right">
          <div v-for="(zone, i) in zones" :key="`zone_${i}`" class="mb-4">
            <div class="d-flex align-center justify-space-between mb-2">
              <p class="mb-0 font-weight-bold mr-2">
                {{ zone.name }}
              </p>
              <v-btn
                outlined
                small
                color="primary"
                @click="
                  selected_tables = [
                    ...selected_tables,
                    ...tables_by_zone[zone.id],
                  ]
                "
              >
                <span class="font-weight-bold">Select all</span>
              </v-btn>
            </div>

            <div class="d-flex flex-row align-center" style="gap: 1rem">
              <div
                v-for="(table, j) in tables_by_zone[zone.id]"
                :key="`table_${i}_${j}`"
                class="--table"
                :class="{ '--active': selected_tables_ids.includes(table.id) }"
                @click="on_table_click(table)"
              >
                <p class="text-body-1">
                  {{ table.name }} ({{ table.capacity }})
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <v-btn
        block
        rounded
        class="mb-4"
        color="primary"
        :loading="is_loading"
        :disabled="!is_valid"
        @click="submit_block_out()"
        >Save new Block Out</v-btn
      >
    </v-form>
    <v-divider color="lightgrey" class="mb-7" height="1px" />
    <p>
      Existing Block Outs <span>({{ block_outs.length }})</span>
    </p>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Name</th>
            <th class="text-left">Start Date</th>
            <th class="text-left">Start At</th>
            <th class="text-left">End Date</th>
            <th class="text-left">End At</th>
            <th class="text-left">Tables Count</th>
            <th class="text-left"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in block_outs" :key="item.id">
            <td>{{ item.name }}</td>
            <td>{{ moment(item.start_date).format("DD/MM/yyyy") }}</td>
            <td>{{ item.start_at.substring(0, 5) }}</td>
            <td>{{ moment(item.end_date).format("DD/MM/yyyy") }}</td>
            <td>{{ item.end_at.substring(0, 5) }}</td>
            <td>{{ item.tables_count }}</td>
            <td>
              <v-btn
                icon
                color="red darken-2"
                @click="delete_booking_block_out(item.id)"
                ><v-icon>mdi-delete</v-icon></v-btn
              >
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import moment from "moment";

import { field_req } from "@/utils/form_val_rules";
import {
  create_booking_block_out,
  get_booking_block_outs,
  delete_block_out,
} from "@/requests";

export default {
  name: "BookingSettingsBlockages",
  computed: {
    ...mapState("AdminStore", ["shop_id"]),
    ...mapState("ShopStore", ["shop"]),
    ...mapState("TableBookingStore", [
      "settings",
      "show_new_booking",
      "is_sending",
      "is_fetching_options",
      "tables",
      "zones",
      "selected_booking_date",
    ]),
    ...mapGetters("TableBookingStore", ["tables_by_zone"]),
    selected_tables_ids() {
      return this.selected_tables.map((t) => t.id);
    },
  },
  async mounted() {
    const { data: blocks } = await get_booking_block_outs(this.shop_id);
    this.block_outs = blocks;
  },
  data() {
    return {
      moment,
      week_days_list: [
        { label: "Monday", value: 0 },
        { label: "Tuesday", value: 1 },
        { label: "Wednesday", value: 2 },
        { label: "Thursday", value: 3 },
        { label: "Friday", value: 4 },
        { label: "Saturday", value: 5 },
        { label: "Sunday", value: 6 },
      ],
      block_outs: [],
      is_loading: false,
      is_valid: false,
      field_req,
      start_at: "08:00",
      end_at: "22:00",
      block_out_name: "",
      date_type: "date",
      block_type: "date",
      start_date: null,
      end_date: null,
      selected_tables: [],
      selected_week_days: [],
    };
  },
  methods: {
    on_table_click(table) {
      const idx = this.selected_tables.indexOf(table);
      if (idx > -1) {
        this.selected_tables.splice(idx, 1);
      } else {
        this.selected_tables.push(table);
      }
    },
    on_week_day_click(iso_week_day) {
      const idx = this.selected_week_days.indexOf(iso_week_day);
      if (idx > -1) {
        this.selected_week_days.splice(idx, 1);
      } else {
        this.selected_week_days.push(iso_week_day);
      }
    },
    async delete_booking_block_out(block_out_id) {
      try {
        this.is_loading = true;
        await delete_block_out(block_out_id);
        const { data: blocks } = await get_booking_block_outs(this.shop_id);
        this.block_outs = blocks;
      } finally {
        this.is_loading = false;
      }
    },
    async submit_block_out() {
      const params = {
        brand_id: this.shop.brands[0].id,
        shop_id: this.shop_id,
        block_type: this.block_type,
        table_ids: this.selected_tables_ids,
        date_type: this.date_type,
        name: this.block_out_name,
        start_date: this.start_date,
        end_date: this.end_date,
        start_at: this.start_at ? this.start_at + ":00" : null,
        end_at: this.end_at ? this.end_at + ":00" : null,
        week_days:
          this.block_type === "day-in-range" ? this.selected_week_days : null,
      };
      try {
        this.is_loading = true;
        await create_booking_block_out(params);
        const { data: blocks } = await get_booking_block_outs(this.shop_id);
        this.block_outs = blocks;
        this.block_type = "date";
        this.block_out_name = "";
        this.date_type = "date";
        this.start_date = null;
        this.end_date = null;
        this.selected_tables = [];
        this.$refs.block_out_form.resetValidation();
      } finally {
        this.is_loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.--table {
  border: 2px solid var(--v-light-grey-base);
  border-radius: 5px;
  display: inline;
  max-width: 200px;
  padding: 5px;
  cursor: pointer;
  &.--active {
    background-color: var(--v-primary-base);
    color: white;
  }
  &:last-child {
    margin-bottom: 0;
  }
  &:hover {
    border-color: var(--v-primary-base);
  }
  p {
    margin: 0;
  }
}

.form {
  width: 100%;
  .--row {
    @media #{map-get($display-breakpoints, 'md-and-up')} {
      padding-bottom: 2rem;
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 2rem;
      grid-template-areas: "left right";
    }
    &:last-of-type {
      padding-bottom: 0;
    }
    div {
      grid-area: left;
      p {
        margin-bottom: 0;
      }
    }
    .v-input {
      grid-area: right;
    }
  }
}
</style>
